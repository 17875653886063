export const VAR_TYPES = {
    booleanType: 'Boolean',
    numberType: 'Number',
    stringType: 'String',
    customisationType: 'Customisation',
}

export const CSL_DASHBOARD_VAR_MAP = {
    rootProp: 'contentSceneLibrary',
    vars: [
        {
            stateVar: 'adBug',
            objectVar: 'adBug.showAdBug',
            varType: VAR_TYPES.booleanType,
        },
        {
            stateVar: 'adBugPos',
            objectVar: 'adBug.adBugPos',
            varType: VAR_TYPES.stringType,
        },
        {
            stateVar: 'streamStartCountdown',
            objectVar: 'streamStartup.streamStartCountdown',
            varType: VAR_TYPES.booleanType,
        },
        {
            stateVar: 'countdownTime',
            objectVar: 'streamStartup.streamStartCountdownTime',
            varType: VAR_TYPES.numberType,
        },
        {
            stateVar: 'autoHideAfterCountdown',
            objectVar: 'streamStartup.autoHideAfterCountdown',
            varType: VAR_TYPES.booleanType,
        },
        {
            stateVar: 'overlayState',
            objectVar: 'overlayState.currentView',
            varType: VAR_TYPES.stringType,
        },
    ]
}

export const CUSTOMISED_ASSETS_BASE_URL = 'feature-assets';

export const CUSTOMISED_URL_MAP = {
    video: {
        dir: 'video'
    },
    image: {
        dir: 'image'
    },
    audio: {
        dir: 'audio'
    }
}

export const findObjectVarLoc = (varMap, stateVar) => {
    for (let i = 0; i < varMap.vars.length; i++) {
        if (varMap.vars[i].stateVar === stateVar) {
            return varMap.vars[i].objectVar;
        }
    }
    return null;
}

export const checkAndUpdateObjectVar = (object, rootProp, objectVarLoc, val, varType = VAR_TYPES.stringType) => {
    console.log('checkAndUpdateObjectVar: ', object, rootProp, objectVarLoc, val, varType);
    let objectUpdated = false;
    let currentRef = getObjectRef(object, rootProp, objectVarLoc);
    if (currentRef) {
        // Always compare as strings...
        if (String(currentRef.propRef[currentRef.prop]) != String(val)) {
            // Cast to correct type before we set the value
            switch (varType) {
                case VAR_TYPES.numberType:
                    val = Number(val);
                    break;
                case VAR_TYPES.booleanType:
                    val = Boolean(val);
                    break;
                case VAR_TYPES.stringType:
                    val = String(val);
                    break;
                default:
                    /* val = val; */
                    break;
            }
            currentRef.propRef[currentRef.prop] = val;
            objectUpdated = true;
        }
    }
    return objectUpdated;
}

export const getValFromObject = (object, rootProp, objectVarLoc) => {
    // console.log(object, rootProp, objectVarLoc)
    let currentRef = getObjectRef(object, rootProp, objectVarLoc);
    if (currentRef) {
        return currentRef.propRef[currentRef.prop];
    }
    return null;
}

export const getObjectRef = (object, rootProp, objectVarLoc) => {
    let currentRef = findPropRefObj(object, rootProp);
    if (currentRef) {
        currentRef = currentRef.propRef[currentRef.prop];
        currentRef = findPropRefObj(currentRef, objectVarLoc);
    }
    return currentRef;
}

/* Some internal private functions */
const findPropRefObj = (object, objectVarLoc) => {
    const varRefArr = objectVarLoc.split('.');
    let currentRef = object;
    let refExists = true;
    for (let i = 0; i < varRefArr.length; i++) {
        // Support arrays for finding values in customisations
        if (Array.isArray(currentRef)) {
            currentRef = currentRef[0];
        }
        if (currentRef?.hasOwnProperty(varRefArr[i])) {
            if (i < varRefArr.length - 1) {
                currentRef = currentRef[varRefArr[i]];
            }
        } else {
            refExists = false;
            break;
        }
    }
    if (refExists) {
        return { propRef: currentRef, prop: varRefArr[varRefArr.length - 1] };
    }
    return refExists;
}
