import { useEffect, useRef, useState } from "react";
import "./RandomiserStatus.scss";
import Toggle from "../../../forms/Toggle/Toggle";
import FormDropDown from "../../../forms/DropDownList/FormDropDown";
import { VAR_TYPES } from "../../../../config/FeatureDashboardVarMap";

const RandomiserResultSelector = (props) => {
    if (!props.featureReady || !props.chatConnected) {
        return null;
    }

    const getStateData = () => {
        let stateData = null;
        if (props.previewControls && props?.passedInPreviewData) {
            stateData = props.passedInPreviewData;
        }
        if (stateData === null) {
            stateData = props?.featureVars;
        }
        if (stateData) {
            return stateData;
        }
        // Default state data
        return {};
    }

    const stateData = getStateData();
    console.log('RandomiserResultSelector props', props);
    console.log('RandomiserResultSelector stateData', stateData);

    const active = stateData?.randomiserStatus?.active;
    const spinning = stateData?.randomiserStatus?.spinning;
    const enableToggle = active === false || spinning === false;
    let toggleTo = enableToggle ? !stateData?.nextOutcome?.fixOutcome : false;
    if (typeof stateData?.nextOutcome?.fixOutcome !== "boolean") {
        toggleTo = true;
    }

    const dropDownData = [];
    const selectedIndexes = [];
    for (let i = 0; i < stateData?.randomiserOptions?.currentOptions?.length; i++) {
        if (stateData?.randomiserOptions?.currentOptions[i].length) {
            dropDownData.push([]);
            for (let j = 0; j < stateData?.randomiserOptions?.currentOptions[i].length; j++) {
                if (stateData?.randomiserOptions?.currentOptions?.[i]?.[j]?.included && stateData?.randomiserOptions?.currentOptions?.[i]?.[j]?.spent === false) {
                    dropDownData[i].push({
                        label: stateData?.randomiserOptions?.currentOptions[i][j].originalOptionData.name,
                        value: j,
                        val: j,
                        index: j
                    });
                    if (stateData?.nextOutcome?.outcomeIndex?.[i] === j) {
                        selectedIndexes.push(dropDownData[i].length - 1);
                    }
                }
            }
        }
    }

    return (
        <div className="randomiser-status fl-column">
            <div className={`fl-row`}>
                <div className="form-field-content label">Fix Next Outcome</div>
                <div className="grow-100"></div>
                <Toggle
                    id={"randomiser_fix_outcome"}
                    currentState={stateData?.nextOutcome?.fixOutcome ?? false}
                    toggleFunc={(e, id) => { props.changeValFunc("nextOutcome", "fixOutcome", toggleTo, true, false, true, VAR_TYPES.booleanType) }}
                    enabled={enableToggle}
                />
            </div>
            {stateData?.nextOutcome?.fixOutcome === true && dropDownData.map((dropDown, index) => {
                return (
                    <FormDropDown
                        key={index}
                        id={"randomiser_outcome_" + index}
                        items={dropDown}
                        currentIndex={ (selectedIndexes[index] > 0 && selectedIndexes[index] < dropDown.length) ? selectedIndexes[index] : 0 }
                        label="Next Outcome"
                        selectFunc={
                            (e, id, item) => { 
                                stateData.nextOutcome.outcomeIndex[index] = item.value; 
                                props.changeValFunc("nextOutcome", "outcomeIndex", stateData?.nextOutcome?.outcomeIndex); 
                            }
                        }
                        enabled={enableToggle && stateData?.nextOutcome?.fixOutcome === true}
                        showErrors={false}
                    />
                );
            })}
            {stateData?.nextOutcome?.fixOutcome === false &&
                <div className={`fl-column compact`}>
                    {stateData?.randomiserStatus.spinning === false &&
                        <div className="light-text">
                            Next outcome will be randomly decided on the next spin
                        </div>
                    }
                    {stateData?.randomiserStatus.spinning === true &&
                        <>
                            <div className="var-label">
                                Next Outcome:
                            </div>
                            {stateData?.nextOutcome?.outcomeIndex.map((index, i) => {
                                if (index === null || index === undefined || index === -1 || index > stateData?.randomiserOptions?.currentOptions[i].length) {
                                    return null;
                                }
                                return (
                                    <div key={index} className="one-randomiser-option">
                                        <div className="bold-text">
                                            {stateData?.randomiserOptions?.currentOptions[i][index].originalOptionData.name}
                                        </div>
                                        <div>
                                            {stateData?.randomiserOptions?.currentOptions[i][index].originalOptionData.description}
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    }
                </div>
            }
            {stateData?.error && stateData?.error?.message && stateData?.error?.message !== "" &&
                <div className="bold-text error">{stateData.error.message}</div>
            }
        </div>
    );
}

export default RandomiserResultSelector;