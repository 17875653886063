import { useEffect, useRef, useState } from "react";
import "./RandomiserStatus.scss";
import Toggle from "../../../forms/Toggle/Toggle";

const RandomiserOptionsList = (props) => {
    const updateUnsavedTimeoutId = useRef(null);

    if (!props.featureReady || !props.chatConnected) {
        return (
            <div className="randomiser-status fl-column">
                <div className={`bold-text error`}>
                    Please load the OBS extension and connect to the chat before using the randomiser!
                </div>
            </div>
        );
    }

    const getStateData = () => {
        let stateData = null;
        if (props.previewControls && props?.passedInPreviewData) {
            stateData = props.passedInPreviewData;
        }
        if (stateData === null) {
            stateData = props?.featureVars;
        }
        if (stateData) {
            return stateData;
        }
        // Default state data
        return {};
    }

    const stateData = getStateData();
    console.log('RandomiserOptionsList props', props);
    console.log('RandomiserOptionsList stateData', stateData);
    const randomiserType = props?.customisationData?.setup?.globalSettings?.general?.[0]?.randomiserType;
    const randomiserSetup = props?.customisationData?.setup?.globalSettings?.[randomiserType + 'Settings']?.[0];
    console.log('RandomiserOptionsList randomiserSetup', randomiserType, randomiserSetup);

    // Do we have any unsaved changes?
    let hasUnsavedChanges = false;
    for (let i = 0; i < stateData?.randomiserOptions?.currentOptions.length; i++) {
        for (let j = 0; j < stateData?.randomiserOptions?.currentOptions[i].length; j++) {
            if (stateData?.randomiserOptions?.currentOptions[i][j].changed) {
                hasUnsavedChanges = true;
                break;
            }
        }
    }

    if (updateUnsavedTimeoutId.current === null && props.featureVars.randomiserOptionsControl.hasChanges !== hasUnsavedChanges) {
        updateUnsavedTimeoutId.current = setTimeout(() => {
            props.changeValFunc("randomiserOptionsControl", "hasChanges", hasUnsavedChanges, false, false, true);
            updateUnsavedTimeoutId.current = null;
        }, 10);
    }

    return (
        <>
            <div className="randomiser-option-list-box fl-column">
                {stateData?.randomiserOptions?.currentOptions[0].map((option, index) => {
                    return (
                        <div key={index} className={`one-randomiser-option${option.included === false ? ' not-included' : ''}${option.spent === true ? ' spent' : ''}${option.changed === true ? ' changed' : ''}`}>
                            <div className="fl-row">
                                <div className="fl-column fl-start compact grow">
                                    <div className="bold-text">
                                        {option.originalOptionData.name}
                                    </div>
                                    <div>
                                        {option.originalOptionData.description}
                                    </div>
                                </div>
                                <div className="fl-column fl-start compact shrink">
                                    <div className={`fl-row grow-100`}></div>
                                    <div className={`fl-row grow`}>
                                        <div className="form-field-content label">Included</div>
                                        <div className="grow-100"></div>
                                        <Toggle
                                            id={"randomiser_fix_outcome"}
                                            currentState={option.included}
                                            toggleFunc={(e, id) => { option.included = !option.included; option.changed = true; props.changeValFunc("randomiserOptions", "currentOptions", stateData?.randomiserOptions?.currentOptions) }}
                                            enabled={true}
                                        />
                                    </div>
                                    {option.included && randomiserSetup && randomiserSetup.removeSpentOptions &&
                                        <div className={`fl-row grow`}>
                                            <div className="form-field-content label">Spent</div>
                                            <div className="grow-100"></div>
                                            <Toggle
                                                id={"randomiser_fix_outcome"}
                                                currentState={option.spent}
                                                toggleFunc={(e, id) => { option.spent = !option.spent; option.changed = true; props.changeValFunc("randomiserOptions", "currentOptions", stateData?.randomiserOptions?.currentOptions) }}
                                                enabled={true}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            {option.changed &&
                                <div className="fl-row error">
                                    Changes need to be applied to take effect!
                                </div>
                            }
                        </div>
                    );
                })}
            </div>
            {!hasUnsavedChanges &&
                <button className="standard-button live-but-orange no-bottom-margin" onClick={(e) => props.sendFeatureCommandFunc(e, "editRandomizerOptions", props.featureId, 1)}>
                    <div className="fl-row">Edit Options</div>
                </button>
            }
        </>
    );
}

export default RandomiserOptionsList;